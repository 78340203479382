<template>
  <ProductListSlider
    v-if="!loading"
    :categoryId="categoryId"
    :productList="productList"
    :layout="layout"
    :shuffle="shuffle"
    :promo="promo"
    :limit="limit"
    :title="title"
    :paginationClass="paginationClass"
    :customBreakpoints="breakpoints"
    position="wordpress"
  />
</template>
<style>
@import "/p/wp-content/themes/tigros2020/styles-common.css";
</style>
<script>
import split from "lodash/split";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ProductService from "~/service/productService";

export default {
  name: "WpProductListSlider",
  props: {
    categoryId: { type: String, required: false },
    productIds: { type: String, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 12 },
    title: { type: String, required: false },
    slidesPerView: { type: String, required: false, default: "{}" },
    slidesPerGroup: { type: String, required: false, default: "{}" },
    paginationClass: {
      type: String,
      default: "swiper-wp-pagination-slider"
    }
  },

  components: { ProductListSlider },
  data() {
    return {
      productList: [],
      loading: true,
      breakpoints: null
    };
  },
  async created() {
    if (this.productIds) {
      //split by comma
      let ids = split(this.productIds, ",");
      let result = await ProductService.getProductsByIds(ids);
      this.productList = result.products;
    } else if (this.categoryId && !this.layout) {
      let result = await ProductService.getProductsByCategoryId(
        this.categoryId
      );
      this.productList = result.products;
    }

    const slidesPerViewObject = JSON.parse(
      this.slidesPerView.replace(/'/g, '"')
    );
    const slidesPerGroupObject = JSON.parse(
      this.slidesPerGroup.replace(/'/g, '"')
    );

    const breakpoints = {};

    for (const key in slidesPerViewObject) {
      breakpoints[key] = {
        slidesPerView: slidesPerViewObject[key],
        slidesPerGroup: slidesPerGroupObject[key] || 1
      };
    }

    for (const key in slidesPerGroupObject) {
      if (!breakpoints[key]) {
        breakpoints[key] = {
          slidesPerGroup: slidesPerGroupObject[key]
        };
      }
    }

    this.breakpoints = breakpoints;
    console.log("Breakpoints:", breakpoints);
    this.loading = false;
  }
};
</script>
