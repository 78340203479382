<template>
  <div class="allibo-joblist-container" v-if="jobs">
    <v-row class="allibo-filters">
      <v-col cols="12" sm="4" md="4" xl="4" v-if="availableJobTypes">
        <v-select
          :items="availableJobTypes"
          v-model="selectedType"
          @change="filterJobs"
          label="RUOLO"
          class="allibo-filter-job-type"
          filled
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4">
        <v-select
          :items="availableJobTimes"
          v-model="selectedTime"
          @change="filterJobs"
          label="TEMPO"
          class="allibo-filter-job-time"
          filled
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4">
        <v-select
          :items="availableProvinces"
          v-model="selectedProvince"
          @change="filterJobs"
          label="PROVINCIA"
          class="allibo-filter-job-provinces"
          filled
          hide-details
        ></v-select>
      </v-col>
    </v-row>

    <v-row
      v-for="job in filteredJobs"
      class="allibo-job-wrapper"
      :key="job.StyleClass + Math.random()"
    >
      <v-col cols="0">
        <a :href="job.DetailLink" target="_blank" class="allibo-job">
          <!-- {{ job.Job }}<br /> -->
          {{ job.Title }}</a
        >
      </v-col>
      <v-col
        cols="2"
        sm="1"
        class="d-flex flex-wrap justify-center align-center"
      >
        <v-icon @click="copy(job.DetailLink)" large color="primary">
          $copy
        </v-icon>
        <v-icon
          class="d-sm-none"
          large
          color="primary"
          @click="shareOnWA(job.DetailLink)"
          >icon-whatsapp
        </v-icon>
      </v-col>
    </v-row>
  </div>
  <div v-else>Non ci sono jobs!</div>
</template>
<style scoped lang="scss">
::v-deep .allibo-filters .v-input__slot {
  background: rgba(49, 46, 129, 0.2) !important;
}
.allibo-filters {
  margin-bottom: 16px;
}
.allibo-job {
  display: block;
  padding: 16px;
  border: 1px $primary solid;
  border-radius: 16px;
  box-shadow: 0 0 4px 1px $primary;
  // margin-bottom: 16px;
  text-decoration: none;
  font-weight: bold;
}
</style>
<script>
import axios from "~/plugins/axios";
import cloneDeep from "lodash/cloneDeep";

axios.defaults.withCredentials = false;
export default {
  props: {
    alliboDM: { type: String, required: true },
    alliboFT: { type: String, required: true },
    jobTimes: { type: String, required: true },
    jobTypes: { type: String, required: true }
  },
  data() {
    return {
      jobs: null,
      filteredJobs: null,
      provinces: null,
      defaultType: "TUTTI",
      defaultTime: "TUTTI",
      defaultProvince: "TUTTE",
      selectedType: null,
      selectedTime: null,
      selectedProvince: null
    };
  },
  components: {},
  computed: {
    availableJobTypes() {
      return [this.defaultType, ...[...new Set(this.jobs.map(job => job.Job))]];
    },
    availableJobTimes() {
      return [this.defaultTime, ...this.jobTimes.split(",")];
    },
    availableProvinces() {
      return [
        this.defaultProvince,
        ...[...new Set(this.jobs.map(job => job.ListPlaces[0].ProvinceName))]
      ];
    }
  },
  methods: {
    async fetchJobs(dm, ft) {
      let url = `https://joblink.allibo.com/ats9/Widget/connector.aspx?DM=${dm}&SG=2&FT=${ft}&LN=IT`;
      return axios.get(url).then(response => {
        return response.data;
      });
    },
    filterJobs() {
      let filteredJobs = this.jobs;

      // Filter by role/type
      if (this.selectedType !== this.defaultType) {
        filteredJobs = filteredJobs.filter(job =>
          job.Job.toLowerCase().includes(this.selectedType.toLowerCase())
        );
      }

      // console.log("F1:", filteredJobs);

      // Filter by time
      if (this.selectedTime !== this.defaultTime) {
        filteredJobs = filteredJobs.filter(job =>
          job.Title.toLowerCase().includes(this.selectedTime.toLowerCase())
        );
      }

      // console.log("F2:", filteredJobs);

      // Filter by province
      if (this.selectedProvince !== this.defaultProvince) {
        filteredJobs = filteredJobs.filter(
          job => job.ListPlaces[0].ProvinceName === this.selectedProvince
        );
      }

      // console.log("F3:", filteredJobs);

      this.filteredJobs = filteredJobs;
    },
    copy(detailLink) {
      navigator.clipboard.writeText(detailLink);
    },
    shareOnWA(detailLink) {
      window.location.href = `https://api.whatsapp.com/send?text=${detailLink}`;
    }
  },
  async created() {
    const data = await this.fetchJobs(this.alliboDM, this.alliboFT);
    this.jobs = data?.Content?.WidgetData?.AdsList;
    this.filteredJobs = cloneDeep(this.jobs);

    this.selectedType = this.defaultType;
    this.selectedTime = this.defaultTime;
    this.selectedProvince = this.defaultProvince;
  }
};
</script>
